export default [
    {
        path: '/payrolls',
        name: 'payrolls',
        props: true,
        component: () => import('@/views/payrolls/index.vue'),
        meta: {
            pageTitle: 'Payrolls',
            breadcrumb: [
                {
                text: 'Payrolls',
                active: true,
                },
            ],
        },
    },
    {
        path: '/payrolls/add',
        name: 'create-payrolls',
        component: () => import('@/views/payrolls/create.vue'),
        meta: {
            pageTitle: 'Create Payrolls',
            breadcrumb: [
                {
                text: 'Payrolls',
                to: { name: 'payrolls' },
                },
                {
                text: 'Create Payrolls',
                active: true,
                },
            ],
        },
    },
    {
        path: '/payrolls/:id',
        name: 'detail-payrolls',
        props: true,
        component: () => import('@/views/payrolls/detail.vue'),
        meta: {
            pageTitle: 'Detail Payrolls',
            breadcrumb: [
                {
                text: 'Payrolls',
                to: { name: 'payrolls' },
                },
                {
                text: 'Detail Payrolls',
                active: true,
                },
            ],
        },
    },
]