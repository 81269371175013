export default [
    {
        path: '/settings/organizations',
        name: 'organizations',
        props: true,
        component: () => import('@/views/settings/organizations/index.vue'),
        meta: {
            pageTitle: 'Organizations',
            breadcrumb: [
                {
                text: 'Organizations',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/organizations/add',
        name: 'create-organizations',
        component: () => import('@/views/settings/organizations/create.vue'),
        meta: {
            pageTitle: 'Create Organization',
            breadcrumb: [
                {
                text: 'Organizations',
                to: { name: 'organizations' },
                },
                {
                text: 'Create Organization',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/organizations/:id',
        name: 'detail-organizations',
        props: true,
        component: () => import('@/views/settings/organizations/detail.vue'),
        meta: {
            pageTitle: 'Detail Organization',
            breadcrumb: [
                {
                text: 'Organizations',
                to: { name: 'organizations' },
                },
                {
                text: 'Detail Organization',
                active: true,
                },
            ],
        },
    },
]