export default [
    {
        path: '/attendances',
        name: 'attendances',
        props: true,
        component: () => import('@/views/attendances/index.vue'),
        meta: {
            pageTitle: 'Attendances',
            breadcrumb: [
                {
                text: 'Attendances',
                active: true,
                },
            ],
        },
    },
    {
        path: '/attendances/:id',
        name: 'detail-attendances',
        props: true,
        component: () => import('@/views/attendances/detail.vue'),
        meta: {
            pageTitle: 'Detail Attendance',
            breadcrumb: [
                {
                text: 'Attendances',
                to: { name: 'attendances' },
                },
                {
                text: 'Detail Attendance',
                active: true,
                },
            ],
        },
    },
]