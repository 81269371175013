export default [
    {
        path: '/permits',
        name: 'permits',
        props: true,
        component: () => import('@/views/permits/index.vue'),
        meta: {
            pageTitle: 'Permits',
            breadcrumb: [
                {
                text: 'Permits',
                active: true,
                },
            ],
        },
    },
    {
        path: '/permits/:id',
        name: 'detail-permits',
        props: true,
        component: () => import('@/views/permits/detail.vue'),
        meta: {
            pageTitle: 'Detail Permit',
            breadcrumb: [
                {
                text: 'Permits',
                to: { name: 'permits' },
                },
                {
                text: 'Detail Permit',
                active: true,
                },
            ],
        },
    },
]