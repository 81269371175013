export default [
    {
        path: '/settings/departments',
        name: 'departments',
        props: true,
        component: () => import('@/views/settings/departments/index.vue'),
        meta: {
            pageTitle: 'Departments',
            breadcrumb: [
                {
                text: 'Departments',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/add',
        name: 'create-departments',
        component: () => import('@/views/settings/departments/create.vue'),
        meta: {
            pageTitle: 'Create Department',
            breadcrumb: [
                {
                text: 'Departments',
                to: { name: 'departments' },
                },
                {
                text: 'Create Department',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/:id',
        name: 'detail-departments',
        props: true,
        component: () => import('@/views/settings/departments/detail.vue'),
        meta: {
            pageTitle: 'Detail Department',
            breadcrumb: [
                {
                text: 'Departments',
                to: { name: 'departments' },
                },
                {
                text: 'Detail Department',
                active: true,
                },
            ],
        },
    },
]