export default [
    {
        path: '/settings/employee-status',
        name: 'employee-status',
        props: true,
        component: () => import('@/views/settings/employee-status/index.vue'),
        meta: {
            pageTitle: 'Employee Status',
            breadcrumb: [
                {
                text: 'Employee Status',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-status/add',
        name: 'create-employee-status',
        component: () => import('@/views/settings/employee-status/create.vue'),
        meta: {
            pageTitle: 'Create Employee Status',
            breadcrumb: [
                {
                text: 'Employee Status',
                to: { name: 'employee-status' },
                },
                {
                text: 'Create Employee Status',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-status/:id',
        name: 'detail-employee-status',
        props: true,
        component: () => import('@/views/settings/employee-status/detail.vue'),
        meta: {
            pageTitle: 'Detail Employee Status',
            breadcrumb: [
                {
                text: 'Employee Status',
                to: { name: 'employee-status' },
                },
                {
                text: 'Detail Employee Status',
                active: true,
                },
            ],
        },
    },
]