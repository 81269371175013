export default [
    {
        path: '/settings/divisions',
        name: 'divisions',
        props: true,
        component: () => import('@/views/settings/divisions/index.vue'),
        meta: {
            pageTitle: 'Divisions',
            breadcrumb: [
                {
                text: 'Divisions',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/divisions/add',
        name: 'create-divisions',
        component: () => import('@/views/settings/divisions/create.vue'),
        meta: {
            pageTitle: 'Create Division',
            breadcrumb: [
                {
                text: 'Divisions',
                to: { name: 'divisions' },
                },
                {
                text: 'Create Division',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/divisions/:id',
        name: 'detail-divisions',
        props: true,
        component: () => import('@/views/settings/divisions/detail.vue'),
        meta: {
            pageTitle: 'Detail Division',
            breadcrumb: [
                {
                text: 'Divisions',
                to: { name: 'divisions' },
                },
                {
                text: 'Detail Division',
                active: true,
                },
            ],
        },
    },
]