export default [
    {
        path: '/settings/employee-ptkp',
        name: 'employee-ptkp',
        props: true,
        component: () => import('@/views/settings/employee-ptkp/index.vue'),
        meta: {
            pageTitle: 'Employee PTKP',
            breadcrumb: [
                {
                text: 'Employee PTKP',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-ptkp/add',
        name: 'create-employee-ptkp',
        component: () => import('@/views/settings/employee-ptkp/create.vue'),
        meta: {
            pageTitle: 'Create Employee PTKP',
            breadcrumb: [
                {
                text: 'Employee PTKP',
                to: { name: 'employee-ptkp' },
                },
                {
                text: 'Create Employee PTKP',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-ptkp/:id',
        name: 'detail-employee-ptkp',
        props: true,
        component: () => import('@/views/settings/employee-ptkp/detail.vue'),
        meta: {
            pageTitle: 'Detail Employee PTKP',
            breadcrumb: [
                {
                text: 'Employee PTKP',
                to: { name: 'employee-ptkp' },
                },
                {
                text: 'Detail Employee PTKP',
                active: true,
                },
            ],
        },
    },
]