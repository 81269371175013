export default [
    {
        path: '/overtimes',
        name: 'overtimes',
        props: true,
        component: () => import('@/views/overtimes/index.vue'),
        meta: {
            pageTitle: 'Overtimes',
            breadcrumb: [
                {
                text: 'Overtimes',
                active: true,
                },
            ],
        },
    },
    {
        path: '/overtimes/:id',
        name: 'detail-overtimes',
        props: true,
        component: () => import('@/views/overtimes/detail.vue'),
        meta: {
            pageTitle: 'Detail Overtime',
            breadcrumb: [
                {
                text: 'Overtimes',
                to: { name: 'overtimes' },
                },
                {
                text: 'Detail Overtime',
                active: true,
                },
            ],
        },
    },
]