export default [
    {
        path: '/employees',
        name: 'employees',
        props: true,
        component: () => import('@/views/employees/index.vue'),
        meta: {
            pageTitle: 'Employees',
            breadcrumb: [
                {
                text: 'Employees',
                active: true,
                },
            ],
        },
    },
    {
        path: '/employees/add',
        name: 'create-employees',
        component: () => import('@/views/employees/create.vue'),
        meta: {
            pageTitle: 'Create Employee',
            breadcrumb: [
                {
                text: 'Employees',
                to: { name: 'employees' },
                },
                {
                text: 'Create Employee',
                active: true,
                },
            ],
        },
    },
    {
        path: '/employees/:id',
        name: 'detail-employees',
        props: true,
        component: () => import('@/views/employees/detail.vue'),
        meta: {
            pageTitle: 'Detail Employee',
            breadcrumb: [
                {
                text: 'Employees',
                to: { name: 'employees' },
                },
                {
                text: 'Detail Employee',
                active: true,
                },
            ],
        },
    },
]