export default [
    {
        path: '/permit-quota',
        name: 'permit-quota',
        props: true,
        component: () => import('@/views/permit-quota/index.vue'),
        meta: {
            pageTitle: 'Permit Quota',
            breadcrumb: [
                {
                text: 'Permit Quota',
                active: true,
                },
            ],
        },
    },
    {
        path: '/permit-quota/add',
        name: 'create-permit-quota',
        component: () => import('@/views/permit-quota/create.vue'),
        meta: {
            pageTitle: 'Create Permit Quota',
            breadcrumb: [
                {
                text: 'Permit Quota',
                to: { name: 'permit-quota' },
                },
                {
                text: 'Create Permit Quota',
                active: true,
                },
            ],
        },
    },
    {
        path: '/permit-quota/:id',
        name: 'detail-permit-quota',
        props: true,
        component: () => import('@/views/permit-quota/detail.vue'),
        meta: {
            pageTitle: 'Detail Permit Quota',
            breadcrumb: [
                {
                text: 'Permit Quota',
                to: { name: 'permit-quota' },
                },
                {
                text: 'Detail Permit Quota',
                active: true,
                },
            ],
        },
    },
]