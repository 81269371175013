export default [
    {
        path: '/settings/jobs',
        name: 'jobs',
        props: true,
        component: () => import('@/views/settings/jobs/index.vue'),
        meta: {
            pageTitle: 'Job Positions',
            breadcrumb: [
                {
                text: 'Job Positions',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/jobs/add',
        name: 'create-jobs',
        component: () => import('@/views/settings/jobs/create.vue'),
        meta: {
            pageTitle: 'Create Job Position',
            breadcrumb: [
                {
                text: 'Job Positions',
                to: { name: 'jobs' },
                },
                {
                text: 'Create Job Position',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/jobs/:id',
        name: 'detail-jobs',
        props: true,
        component: () => import('@/views/settings/jobs/detail.vue'),
        meta: {
            pageTitle: 'Detail Job Position',
            breadcrumb: [
                {
                text: 'Job Positions',
                to: { name: 'jobs' },
                },
                {
                text: 'Detail Job Position',
                active: true,
                },
            ],
        },
    },
]