import { password } from '@/@core/utils/validations/validations'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {createRouter,createWebHistory} from 'vue-router'
import dashboard from './routes/dashboard'
import login from './routes/login'
import profile from './routes/profile'
import forgotPassword from './routes/forgotPassword'
import roles from './routes/roles'
import jobs from './routes/jobs'
import organizations from './routes/organizations'
import divisions from './routes/divisions'
import departments from './routes/departments'
import employees from './routes/employees'
import attendances from './routes/attendances'
import overtimes from './routes/overtimes'
import permits from './routes/permits'
import permitQuota from './routes/permitQuota'
import reimbursements from './routes/reimbursements'
import cashAdvance from './routes/cashAdvance'
import payrolls from './routes/payrolls'
import employeeStatus from './routes/employeeStatus'
import employeePtkp from './routes/employeePtkp'
import company from './routes/company'
import error404 from './routes/error404'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...login,
    ...profile,
    ...forgotPassword,
    ...roles,
    ...jobs,
    ...organizations,
    ...divisions,
    ...departments,
    ...employees,
    ...attendances,
    ...overtimes,
    ...permits,
    ...permitQuota,
    ...reimbursements,
    ...cashAdvance,
    ...payrolls,
    ...employeeStatus,
    ...employeePtkp,
    ...company,
    ...error404,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

router.beforeEach((to, from, next) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  
  const isAuthenticated = JSON.parse(sessionStorage.getItem('authenticated'));
  if (to.name !== 'login' && to.name !== 'forgot-password' && !isAuthenticated) next({ path: '/login' });
  // else if (to.name !== 'login' && !isAuthenticated) next({ path: '/' });
  else next();
});

export default router
