export default [
    {
        path: '/reimbursements',
        name: 'reimbursements',
        props: true,
        component: () => import('@/views/reimbursements/index.vue'),
        meta: {
            pageTitle: 'Reimbursement',
            breadcrumb: [
                {
                text: 'Reimbursement',
                active: true,
                },
            ],
        },
    },
    {
        path: '/reimbursements/:id',
        name: 'detail-reimbursements',
        props: true,
        component: () => import('@/views/reimbursements/detail.vue'),
        meta: {
            pageTitle: 'Detail Reimbursement',
            breadcrumb: [
                {
                text: 'Reimbursements',
                to: { name: 'reimbursements' },
                },
                {
                text: 'Detail Reimbursement',
                active: true,
                },
            ],
        },
    },
]