export default [
    {
        path: '/cash-advance',
        name: 'cash-advance',
        props: true,
        component: () => import('@/views/cash-advance/index.vue'),
        meta: {
            pageTitle: 'Cash Advance',
            breadcrumb: [
                {
                text: 'Cash Advance',
                active: true,
                },
            ],
        },
    },
    {
        path: '/cash-advance/:id',
        name: 'detail-cash-advance',
        props: true,
        component: () => import('@/views/cash-advance/detail.vue'),
        meta: {
            pageTitle: 'Detail Cash Advance',
            breadcrumb: [
                {
                text: 'Cash Advance',
                to: { name: 'cash-advance' },
                },
                {
                text: 'Detail Cash Advance',
                active: true,
                },
            ],
        },
    },
]